import { Grid, Hidden, makeStyles, Typography } from '@material-ui/core'
import { useRouter } from 'next/router'
import React from 'react'

import { Override } from '../../utils/types'
import { flattenRoutes, TRoute, TRouteParent } from '../Nav'
import DashboardNavigationDesktop from './DashboardNavigationDesktop'
import DashboardNavigationMobile from './DashboardNavigationMobile'

export * from './Account'

function getActiveRoute(
  routes: TRoutesDashboard,
  path: string,
  basepath = '',
): TRouteDashboard | undefined {
  const flatRoutes = flattenRoutes(routes) as TRouteDashboard[]
  // strip the basepath from the input path
  const basePathTest = new RegExp(`^${basepath}`)
  const testPath = path.replace(basePathTest, '')
  return flatRoutes.find((route) => (route.as || route.href) === testPath)
}

const useStyles = makeStyles((theme) => ({
  dashboardComponentWrapper: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 96px - 90px)',
    },
    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid ${theme.palette.grey[500]}`,
      paddingLeft: theme.spacing(3),
    },
  },
  dashboardTitle: {
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    margin: `0 0 ${theme.spacing(4)}px`,
  },
}))

export type TRouteDashboard = Override<
  TRoute,
  {
    component?: React.ComponentType<{ admin?: boolean; Intro?: React.FC }>
    intro?: React.FC
  }
>

export type TRouteParentDashboard = Override<
  TRouteParent,
  { children: TRouteDashboard[] }
>

export type TRoutesDashboard = (TRouteDashboard | TRouteParentDashboard)[]

export type DashboardProps = {
  routes: TRoutesDashboard
  basepath?: string
  admin?: boolean
}

export type DashboardRouteComponentProps = {
  admin?: boolean
  Intro?: React.FC
}

const Dashboard: React.FC<DashboardProps> = ({
  routes,
  basepath = '',
  admin = false,
}) => {
  const classes = useStyles()
  const asPath = useRouter().asPath.split('?')[0] // asPath without search query
  const activeRoute = getActiveRoute(routes, asPath, basepath)
  if (!activeRoute) return null
  const ActiveComponent = activeRoute.component

  const Intro = activeRoute.intro
  return (
    <Grid container spacing={3}>
      <Hidden smDown>
        <Grid item md={3}>
          <DashboardNavigationDesktop routes={routes} basepath={basepath} />
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <DashboardNavigationMobile routes={routes} basepath={basepath} />
      </Hidden>
      <Grid item xs={12} md={9}>
        {ActiveComponent && (
          <div className={classes.dashboardComponentWrapper}>
            <Typography
              gutterBottom
              variant="h3"
              component="h2"
              className={classes.dashboardTitle}
            >
              {activeRoute.label}
            </Typography>
            <ActiveComponent admin={admin} Intro={Intro} />
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export default Dashboard
