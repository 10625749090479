import { Grid, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useRef, useState } from 'react'

import EditableText from '@aletheia/common/components/EditableText'
import LoadingSpinner from '@aletheia/common/components/__legacy/UI/LoadingSpinner'
import {
  useUpdatePersonByIdMutation,
  useUpdatePersonEmailMutation,
} from '@aletheia/graphql'

import { TAuth0Provider } from '.'
import { usePerson } from '../../PersonProvider'

const NAME_CONSTRAINTS = {
  presence: { allowEmpty: false },
  personName: true,
}

const EMAIL_CONSTRAINTS = {
  presence: { allowEmpty: false },
  email: true,
}

type AccountBasicInfoProps = {
  provider: TAuth0Provider
}

const AccountBasicInfo: React.FC<AccountBasicInfoProps> = ({ provider }) => {
  const [updatePersonById, { loading: PersonLoading }] =
    useUpdatePersonByIdMutation({
      refetchQueries: ['getPerson'],
    })
  const [updatePersonEmail, { loading: PersonEmailLoading }] =
    useUpdatePersonEmailMutation({
      refetchQueries: ['getPerson'],
    })
  const [showEmailEditingMessage, setShowEmailEditingMessage] = useState(false)

  const { Person, refreshPerson } = usePerson()
  // Store a ref to our person so that we can poll for updates
  const PersonRef = useRef(Person)
  useEffect(() => {
    PersonRef.current = Person
  }, [Person])

  /** Update the Person's name */
  const handleUpdateName =
    (field: 'firstName' | 'lastName') => async (name: string) => {
      if (!Person) return
      await updatePersonById({
        variables: {
          personId: Person.id,
          [field]: name,
        },
      })
      await refreshPerson()
    }

  /** Update the Person's email */
  async function handleUpdateEmail(email: string) {
    if (!Person) return
    // update the email
    await updatePersonEmail({
      variables: {
        personId: Person.id,
        email,
      },
    })

    const returnTo = encodeURIComponent(window.location.href)
    window.location.href = `/api/auth/logout?returnTo=${returnTo}`
  }

  if (!Person) return <LoadingSpinner />

  return (
    <>
      <Grid container spacing={2}>
        {provider.managed && (
          <Grid item>
            <Alert severity="info">
              Your profile is managed by {provider.name}. If you make changes to
              your {provider.name} account, they will be reflected here.
            </Alert>
          </Grid>
        )}
        <Grid item xs={6} md={6}>
          <EditableText
            value={Person.firstName}
            label="First name"
            onSave={handleUpdateName('firstName')}
            disabled={PersonLoading || provider.managed}
            constraints={NAME_CONSTRAINTS}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <EditableText
            value={Person.lastName}
            label="Last name"
            onSave={handleUpdateName('lastName')}
            disabled={PersonLoading || provider.managed}
            constraints={NAME_CONSTRAINTS}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EditableText
            value={Person.email}
            label="Email"
            onSave={handleUpdateEmail}
            disabled={PersonEmailLoading || provider.managed}
            constraints={EMAIL_CONSTRAINTS}
            onFocus={() => setShowEmailEditingMessage(true)}
            onBlur={() => setShowEmailEditingMessage(false)}
          />
          {showEmailEditingMessage && (
            <Box marginTop={1}>
              <Alert severity="warning">
                If you change your email, you{`'`}ll need to log in again.
              </Alert>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default AccountBasicInfo
