import {
  Avatar,
  Divider,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'

import ButtonLink from '../ButtonLink'
import { usePerson } from '../PersonProvider'
import { TNavRoutesFlat } from './types'
import { isRoute, isRouteDivider } from './utils'

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.mixins.toolbar,
    borderRadius: 0,
  },
  name: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
  menuItem: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '0.875rem',
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemIcon: {
    minWidth: theme.spacing(4),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}))

type NavUserProps = {
  loggedInRoutes?: TNavRoutesFlat
  loggedOutRoutes?: TNavRoutesFlat
}

export const NavUserDesktop: React.FC<NavUserProps> = ({
  loggedInRoutes,
  loggedOutRoutes,
}) => {
  const { Person, picture } = usePerson()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null as null | HTMLButtonElement)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // we need to get currentTarget in the root of the click handler
    // if we don't, weird things happen with React's synthetic event system
    const newAnchorEl = event.currentTarget
    setAnchorEl((anchorEl) => (anchorEl ? null : newAnchorEl))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const showDropdown = Boolean(anchorEl)

  if (!loggedInRoutes || !loggedOutRoutes) return null

  return (
    <>
      {Person ? (
        <>
          <Button
            onClick={handleClick}
            disableRipple
            aria-controls="user-menu"
            aria-haspopup="true"
            className={classes.button}
          >
            <span className={classes.name}>{Person?.fullName}</span>
            <Avatar
              alt={Person?.fullName || ''}
              src={picture || undefined}
              className={classes.avatar}
            />
            <ExpandMoreIcon />
          </Button>
          <Menu
            id="user-menu"
            open={showDropdown}
            onClose={handleClose}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {loggedInRoutes.map((route, index) => {
              if (isRoute(route)) {
                const Icon = route.icon
                return (
                  <MenuItem
                    key={route.as || route.href}
                    className={classes.menuItem}
                    component={/^\/api/.test(route.href) ? Button : ButtonLink}
                    onClick={handleClose}
                    as={route.as}
                    href={route.href}
                  >
                    {Icon && (
                      <ListItemIcon className={classes.listItemIcon}>
                        <Icon />
                      </ListItemIcon>
                    )}
                    {route.label}
                  </MenuItem>
                )
              } else if (isRouteDivider(route)) {
                return <Divider key={index} />
              }
              return null
            })}
          </Menu>
        </>
      ) : (
        <>
          {loggedOutRoutes.map((route, index) => {
            if (isRoute(route)) {
              const ButtonComponent = /^\/api/.test(route.href)
                ? Button
                : ButtonLink
              return (
                <ButtonComponent
                  key={route.as || route.href}
                  color="inherit"
                  className={classes.button}
                  onClick={handleClose}
                  href={route.href}
                  as={route.as}
                >
                  {route.label}
                </ButtonComponent>
              )
            } else if (isRouteDivider(route)) {
              return <Divider key={index} />
            }
            return null
          })}
        </>
      )}
    </>
  )
}

export default NavUserDesktop
