import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  BottomNavigationActionProps,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
} from '@material-ui/core'
import { ExpandLess } from '@material-ui/icons'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import { DashboardProps } from '.'
import ButtonLink from '../ButtonLink'
import { isRoute, isRouteParent, TRoute, TRouteParent } from '../Nav'

type NavigationRouteProps = {
  route: TRoute
  isChild?: boolean
  basepath: string
} & Omit<
  BottomNavigationActionProps<typeof ButtonLink>,
  'button' | 'component' | 'href'
>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavigationRoute = React.forwardRef<any, NavigationRouteProps>(
  ({ route, basepath, ...bottomNavigationActionProps }, ref) => {
    const Icon = route.icon
    return (
      <BottomNavigationAction
        ref={ref}
        label={route.label}
        value={`${basepath}${route.as || route.href}`}
        icon={Icon && <Icon />}
        component={ButtonLink}
        href={`${basepath}${route.href}`}
        as={`${basepath}${route.as}`}
        {...bottomNavigationActionProps}
      />
    )
  },
)

NavigationRoute.displayName = 'NavigationRoute'

const NavigationRouteParent: React.FC<
  {
    route: TRouteParent
    basepath: string
    onClick: (route: TRouteParent) => void
  } & Omit<
    BottomNavigationActionProps<typeof Button>,
    'button' | 'component' | 'href'
  >
> = ({ route, basepath, ...bottomNavigationActionProps }) => {
  const Icon = route.icon

  return (
    <BottomNavigationAction
      label={route.label}
      value={route.basePath}
      component={Button}
      icon={
        <span style={{ position: 'relative' }}>
          <ExpandLess
            style={{ color: '#CCC', position: 'absolute', right: '-1em' }}
          />
          {Icon && <Icon />}
        </span>
      }
      {...bottomNavigationActionProps}
    />
  )
}

function getSelectedParentRouteIndex(
  routes: DashboardProps['routes'],
  asPath: string,
  basepath: string,
): number {
  // get the value of the currently-selected path
  let index = 0
  for (const route of routes) {
    if (isRoute(route)) {
      const testVal = `${basepath}${route.as || route.href || ''}`
      if (testVal === asPath) {
        return index
      }
    } else if (isRouteParent(route)) {
      if (
        route.children
          .filter(isRoute)
          .some(
            (childRoute) =>
              `${basepath}${childRoute.as || childRoute.href || ''}` === asPath,
          )
      ) {
        return index
      }
    }
    index++
  }
  return -1
}

const useStyles = makeStyles((theme) => ({
  drawerTitle: {
    '& .MuiTypography-root': {
      color: theme.palette.grey[700],
    },
  },
  drawerChildRouteListItem: {
    paddingLeft: theme.spacing(9),
    '& .MuiTypography-root': {
      fontSize: '0.875rem',
    },
  },
  bottomNavItem: {
    textAlign: 'center',
    lineHeight: 1.1,
  },
}))
const DashboardNavigationMobile: React.FC<DashboardProps> = ({
  routes,
  basepath = '',
}) => {
  const classes = useStyles()
  const asPath = useRouter().asPath.split('?')[0] // asPath without search query
  const [selectedRouteParent, setSelectedRouteParent] = useState<TRouteParent>()
  const SelectedRouteParentIcon = selectedRouteParent?.icon

  return (
    <>
      <AppBar style={{ top: 'auto', bottom: 0 }}>
        <BottomNavigation
          showLabels
          value={getSelectedParentRouteIndex(routes, asPath, basepath)}
          style={{ width: '100%' }}
        >
          {routes.map((route) => {
            if (isRoute(route)) {
              return (
                <NavigationRoute
                  key={route.as || route.href}
                  route={route}
                  basepath={basepath}
                  className={classes.bottomNavItem}
                />
              )
            } else if (isRouteParent(route)) {
              return (
                <NavigationRouteParent
                  key={route.basePath}
                  route={route}
                  basepath={basepath}
                  onClick={() => setSelectedRouteParent(route)}
                />
              )
            }
          })}
        </BottomNavigation>
      </AppBar>
      <SwipeableDrawer
        open={!!selectedRouteParent}
        onOpen={() => undefined}
        onClose={() => setSelectedRouteParent(undefined)}
        anchor="bottom"
      >
        <List>
          <ListItem className={classes.drawerTitle}>
            <ListItemIcon>
              {SelectedRouteParentIcon && <SelectedRouteParentIcon />}
            </ListItemIcon>
            <ListItemText>{selectedRouteParent?.label}:</ListItemText>
          </ListItem>
          <Divider />
          {selectedRouteParent?.children.filter(isRoute).map((route) => (
            <ListItem
              key={route.as || route.href}
              href={`${basepath}${route.href}`}
              as={`${basepath}${route.as}`}
              component={ButtonLink}
              onClick={() => setSelectedRouteParent(undefined)}
              className={classes.drawerChildRouteListItem}
            >
              <ListItemText>{route.label}</ListItemText>
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    </>
  )
}

export default DashboardNavigationMobile
