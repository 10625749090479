import {
  TextField,
  InputAdornment,
  makeStyles,
  IconButton,
  TextFieldProps,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckIcon from '@material-ui/icons/CheckCircle'
import Alert from '@material-ui/lab/Alert'
import React, {
  useState,
  KeyboardEventHandler,
  ChangeEventHandler,
  useEffect,
} from 'react'

import { validateSingle } from '../../utils/validate'

type EditableTextProps = {
  value: string | null | undefined
  label?: string
  onSave: (name: string) => Promise<unknown | void> | unknown | void
  disabled?: boolean
  /** Validate.js constraints */
  constraints?: Record<string, unknown>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme) => ({
  text: {
    borderBottom: `1px dashed`,
  },
  setEditingLink: {
    cursor: 'pointer',
  },
  helperIcon: {
    fontSize: '0.6666rem',
  },
}))

const EditableText: React.FC<EditableTextProps & TextFieldProps> = ({
  value,
  label,
  onSave,
  disabled = false,
  constraints,
  ...textFieldProps
}) => {
  const classes = useStyles()
  const [saving, setSaving] = useState(false)
  const [tempValue, setTempValue] = useState('')
  const [error, setError] = useState(null as string | null)

  useEffect(() => {
    setTempValue(value || '')
  }, [value])

  const isDirty = (value || tempValue) && value !== tempValue
  const validationErrors =
    isDirty && constraints ? validateSingle(tempValue, constraints) : undefined

  const isValid = !validationErrors
  const isDisabled = disabled || saving

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const val = event.target.value
    setTempValue(val)
    setError(null)
  }

  const handleSave = async () => {
    if (!isValid) return
    try {
      setError(null)
      setSaving(true)
      await onSave(tempValue)
    } catch (err: any) {
      console.error(err)
      setError(err.message)
    } finally {
      setSaving(false)
    }
  }

  const handleCancel = () => {
    setError(null)
    if (typeof value === 'string') setTempValue(value)
    else setTempValue('')
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    switch (event.key) {
      case 'Enter':
        handleSave()
        break
      case 'Escape':
        handleCancel()
        break
      default:
      // do nothing
    }
  }

  return (
    <>
      <TextField
        fullWidth
        label={label}
        value={tempValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={isDisabled}
        error={!!validationErrors}
        helperText={
          !isValid ? (
            <>
              {label} {validationErrors?.[0]}
            </>
          ) : isDirty ? (
            !saving ? (
              <>
                Click <CheckIcon className={classes.helperIcon} /> to save or{' '}
                <CancelIcon className={classes.helperIcon} /> to cancel.
              </>
            ) : (
              'Saving...'
            )
          ) : undefined
        }
        InputProps={{
          endAdornment: (
            <>
              {isDirty && !isDisabled && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={`Save ${label} value`}
                    onClick={handleSave}
                    tabIndex={-1}
                    disabled={!isValid}
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    aria-label={`Cancel editing ${label} value`}
                    onClick={handleCancel}
                    tabIndex={-1}
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
        {...textFieldProps}
      />
      {error && <Alert severity="warning">{error}</Alert>}
    </>
  )
}

export default EditableText
