import {
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'

import ButtonLink from '../ButtonLink'
import { usePerson } from '../PersonProvider'
import { TNavRoutesFlat } from './types'
import { isRoute, isRouteDivider } from './utils'

const useStyles = makeStyles((theme) => ({
  name: {},
  listItem: {},
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}))

type NavUserMobileProps = {
  loggedInRoutes?: TNavRoutesFlat
  loggedOutRoutes?: TNavRoutesFlat
  onLinkClick: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void
}

export const NavUserMobile: React.FC<NavUserMobileProps> = ({
  loggedInRoutes,
  loggedOutRoutes,
  onLinkClick,
}) => {
  const { Person, picture } = usePerson()
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  function handleToggleMenu() {
    setOpen((open) => !open)
  }

  if (!loggedInRoutes || !loggedOutRoutes) return null

  return (
    <List>
      {Person ? (
        <>
          <ListItem
            button
            onClick={handleToggleMenu}
            aria-controls="user-menu"
            aria-haspopup="true"
            className={classes.listItem}
          >
            <ListItemIcon>
              <Avatar
                alt={Person?.fullName || ''}
                src={picture || undefined}
                className={classes.avatar}
              />
            </ListItemIcon>
            <ListItemText>
              <span className={classes.name}>{Person?.fullName}</span>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemText>
          </ListItem>
          <Collapse in={open}>
            {loggedInRoutes.map((route, index) => {
              if (isRoute(route)) {
                return (
                  <ListItem
                    button
                    key={route.as || route.href}
                    className={classes.listItem}
                    component={/^\/api/.test(route.href) ? Button : ButtonLink}
                    href={route.href}
                    as={route.as}
                    onClick={onLinkClick}
                  >
                    <ListItemText>{route.label}</ListItemText>
                  </ListItem>
                )
              } else if (isRouteDivider(route)) {
                return <Divider key={index} />
              }
              return null
            })}
          </Collapse>
        </>
      ) : (
        <>
          {loggedOutRoutes.map((route, index) => {
            if (isRoute(route)) {
              return (
                <ListItem
                  button
                  className={classes.listItem}
                  component={/^\/api/.test(route.href) ? Button : ButtonLink}
                  key={route.as || route.href}
                  color="inherit"
                  href={route.href}
                  as={route.as}
                  onClick={onLinkClick}
                >
                  <ListItemText>{route.label}</ListItemText>
                </ListItem>
              )
            } else if (isRouteDivider(route)) {
              return <Divider key={index} />
            }
            return null
          })}
        </>
      )}
    </List>
  )
}

export default NavUserMobile
