import { NetworkStatus } from '@apollo/client'
import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'

import {
  TAddress,
  TPerson,
  useGetSettingsQueryWithReducer,
  useUpdateSettingsContactPreferenceMutation,
} from '@aletheia/graphql'

import { usePerson } from '../../PersonProvider'
import AddressSelector from '../../__legacy/UI/AddressSelector'
import LoadingSpinner from '../../__legacy/UI/LoadingSpinner'
import BasicInfo from './BasicInfo'

export type TAuth0Provider = {
  id: string
  name: string
  managed: boolean
}

const PARFIT_PROVIDER = {
  id: 'parfit',
  name: 'Username / Password',
  managed: false,
}

const UNKNOWN_PROVIDER: TAuth0Provider = {
  id: 'unknown',
  name: 'Unknown provider',
  // assume that if there's an unknown provider that it's managed
  managed: true,
}

const PROVIDERS: TAuth0Provider[] = [
  PARFIT_PROVIDER,
  {
    id: 'auth0',
    name: 'Username / Password',
    managed: false,
  },
  {
    id: 'google-oauth2',
    name: 'Google',
    managed: true,
  },
  {
    id: 'facebook',
    name: 'Facebook',
    managed: true,
  },
  UNKNOWN_PROVIDER,
]

function getAuthProvider(Person: TPerson): TAuth0Provider {
  if (!Person.auth0UserId) return PARFIT_PROVIDER
  // Auth0 Ids are in the format auth0|1234abcd, google-oauth2|abcd1234 etc
  const providerId = Person.auth0UserId?.split('|')[0]
  return (
    PROVIDERS.find((provider) => provider.id === providerId) || UNKNOWN_PROVIDER
  )
}

export const Account: React.FC = () => {
  const { Person } = usePerson()
  const [
    Settings,
    {
      loading: settingsLoading,
      networkStatus: settingsNetworkStatus,
      refetch: refetchSettings,
    },
  ] = useGetSettingsQueryWithReducer({ notifyOnNetworkStatusChange: true })
  const [updateContactPreference] = useUpdateSettingsContactPreferenceMutation()

  async function handleUpdateCurrentAddress(Address: TAddress) {
    if (!Person) return
    await updateContactPreference({
      variables: { personId: Person.id, currentAddressId: Address.id },
    })
    try {
      refetchSettings()
    } catch (err) {
      console.error(err)
    }
  }

  if (!Person) return null

  return (
    <>
      <Typography variant="h5" component="h3" gutterBottom>
        Basic info:
      </Typography>
      <Box marginBottom={6}>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <BasicInfo provider={getAuthProvider(Person)} />
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h5" component="h3" gutterBottom>
        Default Address:
      </Typography>

      {settingsLoading ||
      (!Settings?.ContactPreference && !settingsNetworkStatus) ? (
        <LoadingSpinner small />
      ) : (
        <AddressSelector
          Person={Person}
          addressId={Settings?.ContactPreference?.currentAddressId}
          onSelect={handleUpdateCurrentAddress}
          disabled={
            settingsLoading || settingsNetworkStatus === NetworkStatus.refetch
          }
          fullWidth={false}
        />
      )}
    </>
  )
}

export default Account
