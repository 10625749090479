import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import { DashboardProps } from '.'
import ButtonLink from '../ButtonLink'
import { isRoute, isRouteParent, TRoute, TRouteParent } from '../Nav'

const useDashboardRouteStyles = makeStyles((theme) => ({
  listItem: {
    '& .MuiTypography-root': {
      fontFamily: theme.typography.h1.fontFamily,
    },
    '&.child': {
      border: 'none',
      padding: `0 0 4px ${theme.spacing(9)}px`,
      color: theme.palette.grey[700],
      '& .MuiTypography-root': {
        fontSize: '0.875rem',
      },
    },
    '&.active, &.child.active': {
      color: theme.palette.primary.main,
    },
    '&:hover, &.child:hover': {
      backgroundColor: 'inherit',
      color: theme.palette.primary.main,
    },
  },
  listItemIcon: {
    '.active &': {
      color: 'inherit',
    },
  },
}))

const DashboardRoute: React.FC<
  { route: TRoute; isChild?: boolean; basepath: string } & Omit<
    ListItemProps<typeof ButtonLink>,
    'button' | 'component' | 'href'
  >
> = ({ route, isChild = false, basepath, ...listItemProps }) => {
  const classes = useDashboardRouteStyles()
  const asPath = useRouter().asPath.split('?')[0] // asPath without search query
  const Icon = route.icon
  const href = /^http/.test(route.href)
    ? route.href
    : `${basepath}${route.href}`
  const as = route.as && `${basepath}${route.as}`
  const active = asPath === (as || href)

  return (
    <ListItem
      key={route.as || route.href}
      button={false}
      component={ButtonLink}
      href={href}
      as={as}
      className={classnames(classes.listItem, { child: isChild, active })}
      {...listItemProps}
    >
      {Icon && (
        <ListItemIcon className={classes.listItemIcon}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        className={classnames({ child: isChild, active })}
        color="inherit"
      >
        {route.label}
      </ListItemText>
    </ListItem>
  )
}

const useDashboardRouteParentStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTypography-root': {
      fontFamily: theme.typography.h1.fontFamily,
    },
    '&.child-active': {
      color: theme.palette.primary.main,
    },
  },
  childRoute: {
    fontSize: '0.875rem',
  },
  listItemIcon: {
    '.child-active &': {
      color: 'inherit',
    },
  },
  expandIcon: {
    justifyContent: 'flex-end',
  },
}))

const DashboardRouteParent: React.FC<{
  route: TRouteParent
  basepath: string
}> = ({ route, basepath }) => {
  const classes = useDashboardRouteParentStyles()
  const asPath = useRouter().asPath.split('?')[0] // asPath without search query
  const childActive = route.children
    .filter(isRoute)
    .some(
      (childRoute) =>
        `${basepath}${childRoute.as || childRoute.href}` === asPath,
    )
  const [expanded, setExpanded] = useState(childActive)
  const Icon = route.icon
  return (
    <>
      <ListItem
        key={route.basePath}
        button
        onClick={() => setExpanded((expanded) => !expanded)}
        className={classnames(classes.root, {
          expanded,
          'child-active': childActive,
        })}
      >
        {Icon && (
          <ListItemIcon className={classes.listItemIcon}>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText color="inherit">{route.label}</ListItemText>
        <ListItemIcon
          className={classnames(classes.expandIcon, classes.listItemIcon)}
          color="inherit"
        >
          {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </ListItemIcon>
      </ListItem>
      <Collapse in={expanded}>
        {route.children.map((childRoute) => {
          if (isRoute(childRoute)) {
            return (
              <DashboardRoute
                key={childRoute.as || childRoute.href}
                route={childRoute}
                isChild
                basepath={basepath}
              />
            )
          }
        })}
      </Collapse>
    </>
  )
}

// const useStyles = makeStyles((theme) => ({
// }))

const DashboardNavigationDesktop: React.FC<DashboardProps> = ({
  routes,
  basepath = '',
}) => {
  return (
    <List>
      {routes.map((route) => {
        if (isRoute(route))
          return (
            <DashboardRoute
              key={route.as || route.href}
              route={route}
              basepath={basepath}
            />
          )
        else if (isRouteParent(route))
          return (
            <DashboardRouteParent
              key={route.basePath}
              route={route}
              basepath={basepath}
            />
          )
      })}
    </List>
  )
}

export default DashboardNavigationDesktop
